/* eslint-disable react/prop-types */
import React, { Component } from 'react'

import IconSearch from '../../assets/icons/mapa/icons/search.svg'
import IconLocalizador from '../../assets/icons/mapa/icons/crosshair.svg'

import './Mobile.css'

export default class Mobile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ErrorLocation: [],
      lojas: [],
      itemsToShow: 10,
      hasMoreData: true,
      center: { lat: -23.549982, lng: -46.634907 },
      inputPesquisa: '',
      drawerOpen: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.fetchMoreData = this.fetchMoreData.bind(this)
    this.buscaEndereco = this.buscaEndereco.bind(this)
    this.buscaLocation = this.buscaLocation.bind(this)
  }

  componentDidMount () {
    setTimeout(() => {
      this.buscaLocation()
    }, 4000)
  }

  fetchMoreData () {
    this.setState(state => {
      const itemsToShow = state.itemsToShow + 10
      return {
        itemsToShow
      }
    })
  }

  getCurrentAddress (lat, lng) {
    this.setState({ center: { lat: lat, lng: lng } })
  }

  calculaDistancia (p) {
    const myLat = p.lat
    const myLng = p.lng
    const lojas = this.props.lojas.map(loja => {
      const LatLoja = loja.lat
      const LngLoja = loja.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatLoja) / 180

      const theta = myLng - LngLoja
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      loja.distance = parseFloat(dist.toFixed(1))
      return loja
    })
    return this.setState({
      lojas: lojas.sort(function (a, b) {
        return a.distance - b.distance
      })
    })
  }

  selecionaLoja (idLoja) {
    this.props.atualizaMarcador(idLoja, this.props.lojas)
    this.setState({
      idLojaSelected: idLoja,
      drawerOpen: false
    })
  }

  buscaLocation () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.calculaDistancia(pos)
        this.props.criaMarcador(pos)
        this.selecionaLoja(this.state.lojas[0].id)
      }, this.errorOnLocation)
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }

  buscaEndereco (evt) {
    evt.preventDefault()

    const { geocoder } = this.props
    geocoder.geocode({
      address: this.state.inputPesquisa,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
    (results, status) => {
      console.log('google results', { results: results, status: status })

      if (status === 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }
        this.props.criaMarcador(pos)
        this.calculaDistancia(pos)
        this.selecionaLoja(this.state.lojas[0].id)
      } else {
        console.log('FAIL')
      }
    }

    )
  }

  handleChange (event) {
    this.setState({ inputPesquisa: event.target.value })
  }

  handleScroll (event) {
    const target = event.target
    if (target.scrollTop + target.clientHeight + 200 >= target.scrollHeight) {
      this.fetchMoreData()
    }
  }

  toggleDrawer (state) {
    this.setState({ drawerOpen: state })
  }

  render () {
    const { lojas } = this.state
    return (
      <div id="over-map">
        <div className="form-group form-mobile">
          <form method="post" acceptCharset="UTF-8" autoComplete="off" id="mobile-form" className="clear-block">
            <div className="form-content-question">
              <div className="cd-lc-input-group">
                <input className="cd-lc-form-control" type="text" maxLength="128"
                  name="address" id="address-mobile" size="60" autoComplete="off"
                  placeholder="Bairro, Cidade ou CEP"
                  value={this.state.inputPesquisa}
                  onChange={this.handleChange}
                />
                <span className="cd-lc-input-group-btn">
                  <button className="btn"
                    name="op" onClick={this.buscaEndereco}
                    id="edit-submit"
                  ><img src={IconSearch} style={{ height: 20 + 'px', width: '22px', marginBottom: '3px' }} alt="Icone de pesquisa" /></button>
                  <button id="bt-locale" className="btn" type="button" name="op" onClick={this.buscaLocation}>
                    <img src={IconLocalizador} style={{ height: 23 + 'px', width: '22px', marginBottom: '3px' }} alt="Icone do localizador" />
                  </button>
                </span>
              </div>
            </div>
            <input type="hidden" name="form_build_id" id="form-0168068fce35cf80f346d6c1dbd7344e" value="form-0168068fce35cf80f346d6c1dbd7344e" />
            <input type="hidden" name="form_id" id="edit-clinic-finder-form" value="clinic_finder_form" />
          </form>
        </div>
        <div className={`cd-lc-bottom_drawer ${this.state.drawerOpen ? 'active' : ''}`} id="results_drawer">
          {/* <img src={marker} className="logo" alt="logo" style={{ height: 40 + 'px', width: 40 + 'px' }}/> */}
          <div className="cd-lc-toggle_bar" onClick={(evt) => this.toggleDrawer(!this.state.drawerOpen)}></div>
          <div
            className="cd-lc-result_div--mobile"
            onScroll={this.handleScroll}
          >
            <ul className="cd-loc-ul--result accordion">
              {lojas.slice(0, this.state.itemsToShow).map(loja => (
                <li
                  key={loja.id}
                  onClick={() => { this.selecionaLoja(loja.id) }}
                  id={loja.id}
                  className={loja.id === this.state.idLojaSelected ? 'item-lista-lojas-active item-lista-lojas' : 'item-lista-lojas'}>
                  <div className="primaryLojasContent">
                    <h1 className="stname">{loja.name}</h1>
                    {
                      loja.distance && <p className="strdistance">Distância: {loja.distance}km</p>
                    }
                    <div className="texto-comum-box">
                      {loja.adress}
                      <br />
                      {loja.district} - {loja.city}{' '}
                      - {loja.uf} - {loja.cep}
                    </div>
                    <button
                      className="botao-mais-informacoes-box">
                      Mais Informações
                    </button>
                  </div>
                  <div className="secondLojasContent">
                    <div className="tipo-loja-box">{loja.Tipo}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
